import { Component, Input, OnInit } from '@angular/core';
import { ModalController, SelectCustomEvent } from '@ionic/angular';
import { FormBuilder } from '@angular/forms';
import { IonicColor } from '../../entities/toast/ionic-color';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { Content } from '../../../therapy/entities/content';
import { ApiService } from '../../../api';
import { UsersService } from '../../../user/services/user';
import { ToastService } from '../../services/toast-service/toast-service.service';
import { FileContentService } from '../../services/content/file-content.service';
import { CurafidaAuthService } from '../../../auth/services';
import { PatientReportForm } from './patient-report.form';
import { FileItem } from 'ng2-file-upload';
import { UPLOAD_MIME_TYPE_WHITELIST } from '../../entities/white-list-mime-type';
import { SelectInputItem } from '../curafida-input/curafida-select-input/curafida-select-input.component';
import { format, parseISO } from 'date-fns';
import { PatientReportTemplate } from './patient-report.model';
import { PaginatedResponse, SortBy, SortOrder } from '../../entities/paginated-response';
import { LoadingService } from '../../services/loading/loading.service';
import { ReportTypeModalComponent } from '../../../user/components/report-type-list-modal/report-type-modal.component';
import {
    ExtendedSubmission,
    MyMedaxQuestionnaireSubmission,
} from '../../../my-medax/entities/my-medax-questionnaire-submission';
import { MyMedaxService } from '../../../my-medax/services/my-medax.service';
import { QuestionnaireListModalComponent } from '../../../my-medax/components/questionnaire-list-modal/questionnaire-list-modal.component';
import { User, UserRoles } from '../../../auth/entities/user';
import { QuestionnaireType } from '../../../my-medax/entities/questionnaire-type';
import {
    ActionEmitter,
    ActionType,
    ButtonItemAdapterComponent,
    ItemType,
    TableConfig,
} from '../../../table/entities/table';
import { StringItemAdapterComponent } from '../../../table/components/table-adapter/string-item-adapter.component';
import { CheckboxListConfig, CheckboxListModalComponent } from '../checkbox-list-modal/checkbox-list-modal.component';
import { ConfigService } from '../../../config/services';
import { UploadedContent } from '../curafida-input/curafida-upload-button/curafida-upload-button.component';
import { CheckBoxItemAdapterComponent } from '../../../table/components/table-adapter/checkbox-item-adapter.component';
import { UserListModalComponent } from '../../../user/components/user-list-modal/user-list-modal.component';
import { ModuleName, ModuleLabel } from './curafida-modules';

@Component({
    selector: 'lib-patient-report-modal',
    templateUrl: './patient-report-modal.component.html',
    styleUrls: ['./patient-report-modal.component.scss'],
})
export class PatientReportModalComponent implements OnInit {
    @Input()
    username: string;
    @Input()
    preSelectedSubmission: ExtendedSubmission;
    loggedInUser: User;
    user: User;
    patientReportTemplate: PatientReportTemplate;
    isTemplateSelected: boolean;
    readonly patientReportForm: PatientReportForm;
    whiteListMimeType = UPLOAD_MIME_TYPE_WHITELIST.filter((x) => x.includes('image')).join();
    pseuSubTypeSelect: SelectInputItem[] = [
        { value: false, label: 'DEACTIVATED' },
        { value: true, label: 'ACTIVATED' },
    ];
    letterRecipientList: SelectInputItem[] = [
        { value: 'text', label: 'Text' },
        { value: UserRoles.CAREGIVER, label: UserRoles.CAREGIVER },
        { value: UserRoles.SUPERVISOR, label: UserRoles.SUPERVISOR },
        { value: UserRoles.PATIENT, label: UserRoles.PATIENT },
    ];
    showCreatorUserList: SelectInputItem[] = [
        { value: true, label: 'REPORT.CREATE.SHOW_CREATOR' },
        { value: false, label: 'REPORT.CREATE.HIDE_CREATOR' },
    ];
    templateList: SelectInputItem[] = [];
    footerFileItem: UploadedContent[] = [];
    headerFileItem: UploadedContent[] = [];
    signatureFileItem: UploadedContent[] = [];
    pagResReportTemplates: PaginatedResponse<PatientReportTemplate[]>;
    myMedaxQuestionnaireSubmissions: MyMedaxQuestionnaireSubmission[];
    submissionsTableConfig: TableConfig<ExtendedSubmission[]>;
    moduleTableConfig: TableConfig<{ name: ModuleName; label: ModuleLabel }[]>;
    protected readonly log: Logger;
    private element: HTMLElement;

    constructor(
        private readonly userService: UsersService,
        private readonly fileContentService: FileContentService,
        private readonly modalCtrl: ModalController,
        private readonly toastService: ToastService,
        private readonly authService: CurafidaAuthService,
        public formBuilder: FormBuilder,
        public loggingService: LoggingService,
        private loadingService: LoadingService,
        private myMedaxService: MyMedaxService,
        private configService: ConfigService,
    ) {
        this.log = loggingService.getLogger(this.constructor.name);
        this.patientReportForm = new PatientReportForm(formBuilder);
    }

    async ngOnInit(): Promise<void> {
        this.loggedInUser = this.authService.getSession().user;
        this.pagResReportTemplates = await this.userService.getPatientReportTemplates(this.loggedInUser.groups[0]);
        this.createSelectList();
        this.initSubmissionsTableConfig();
        this.initModulesTableConfig();
        this.user = await this.userService.getUser(this.username);
        let patientText = `${this.user.lastname}, ${this.user.firstname}`;
        if (this.user.birthdate) {
            patientText = patientText + `, ${format(parseISO(this.user.birthdate), 'dd.MM.yyyy')}`;
        }
        this.patientReportForm.group.controls.patientText.setValue(patientText);
        this.patientReportForm.group.controls.reportPseudonymized.setValue(this.user.usePseudonym);
        this.patientReportForm.group.controls.therapist.setValue(
            `${this.loggedInUser.firstname} ${this.loggedInUser.lastname}`,
        );
        await this.getMyMedaxQuestionnaireSubmissions();
        this.submissionsTableConfig.list = PaginatedResponse.init(this.submissionsTableConfig.list.items);
    }

    initSubmissionsTableConfig(): void {
        this.submissionsTableConfig = new TableConfig<ExtendedSubmission[]>();
        this.submissionsTableConfig.hideHeader = true;
        this.submissionsTableConfig.itemSettings = [
            {
                id: 'description',
                prop: 'description',
                header: '',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '85%',
                columnPosition: 0,
            },
            {
                id: 'action_delete',
                prop: 'id',
                header: '',
                type: ItemType.ADAPTER,
                adapter: ButtonItemAdapterComponent,
                icon: 'close',
                actionType: ActionType.DELETE,
                width: '15%',
                color: 'danger',
                columnPosition: 1,
            },
        ];
        this.submissionsTableConfig.list = new PaginatedResponse<ExtendedSubmission[]>();
        this.submissionsTableConfig.list.items = [];
        if (this.preSelectedSubmission) {
            this.submissionsTableConfig.list.items.push(this.addDescriptionToSubmission(this.preSelectedSubmission));
        }
    }

    initModulesTableConfig(): void {
        this.moduleTableConfig = new TableConfig<{ name: ModuleName; label: ModuleLabel }[]>();
        this.moduleTableConfig.hideHeader = true;
        this.moduleTableConfig.itemSettings = [
            {
                id: 'label',
                prop: 'label',
                header: '',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '85%',
                columnPosition: 0,
            },
            {
                id: 'action_delete',
                prop: 'id',
                header: '',
                type: ItemType.ADAPTER,
                adapter: ButtonItemAdapterComponent,
                icon: 'close',
                actionType: ActionType.DELETE,
                width: '15%',
                color: 'danger',
                columnPosition: 1,
            },
        ];
        this.moduleTableConfig.list = new PaginatedResponse<{ name: ModuleName; label: ModuleLabel }[]>();
        this.moduleTableConfig.list.items = [];
    }

    async getMyMedaxQuestionnaireSubmissions(): Promise<void> {
        try {
            this.myMedaxQuestionnaireSubmissions = (
                await this.myMedaxService.getQuestionnaireSubmissionsByUser(
                    this.username,
                    0,
                    null,
                    SortBy.UPDATED_AT,
                    SortOrder.DESC,
                    QuestionnaireType.TASK,
                )
            ).items;
        } catch (e) {
            this.log.error('Error in getMyMedaxQuestionnaireSubmissions', e);
            this.toastService.showToast('Beim Laden der Formulare ist ein Fehler aufgetreten.', IonicColor.danger);
        }
    }

    destroy(): void {
        this.modalCtrl.dismiss().catch(this.log.error);
    }

    async downloadPatientReport(): Promise<void> {
        this.loadingService.startLoadingModal();
        let report: Content;
        this.patientReportForm.group.controls.myMedaxQuestionnaireSubmissionUuids.setValue(
            this.submissionsTableConfig.list.items.map((x) => x.uuid),
        );
        this.patientReportForm.group.controls.dataCategories.setValue(
            this.moduleTableConfig.list.items.map((x) => x.name),
        );
        try {
            const url = `${ApiService.url}patients/${this.username}/patientReport/template/${this.patientReportTemplate.uuid}/generatePdfReport`;
            report = await this.userService.downloadPatientReport(
                url,
                this.patientReportForm.toDto(),
                this.headerFileItem[0]?.fileItem,
                this.footerFileItem[0]?.fileItem,
                this.signatureFileItem[0]?.fileItem,
                this.patientReportForm.group.value.showCreatorUser,
            );
        } catch (err) {
            this.log.error(err);
            this.loadingService.stopLoadingModal();
            this.toastService.showToast('Keine Vorlage für Berichte verfügbar.', IonicColor.danger);
            return;
        }
        try {
            const downloadUrl = `${ApiService.url}patients/${this.username}/patientReport/${report.uuid}/download`;
            await this.fileContentService.openObjectURLinNewWindow(
                downloadUrl,
                this.authService.getSession().tokenSet.access_token,
            );
        } catch (err) {
            this.loadingService.stopLoadingModal();
            this.toastService.showToast('Fehler beim erzeugen des Berichts.', IonicColor.danger);
            return;
        }
        this.loadingService.stopLoadingModal();
        await this.dismissModal();
    }

    addImage(event: FileItem, type: string): void {
        if (type === 'footer') {
            this.footerFileItem[0] = new UploadedContent(event);
        }
        if (type === 'header') {
            this.headerFileItem[0] = new UploadedContent(event);
        }
        if (type === 'signature') {
            this.signatureFileItem[0] = new UploadedContent(event);
        }
    }

    async dismissModal(): Promise<void> {
        await this.modalCtrl.dismiss({});
    }

    deleteFile(event: UploadedContent, type: string): void {
        if (type === 'footer') {
            this.footerFileItem = [];
            if (this.patientReportTemplate.letterFooterImageUuid) {
                this.footerFileItem.push(this.patientReportTemplate.letterFooterImage);
            }
        }
        if (type === 'header') {
            this.headerFileItem = [];
            if (this.patientReportTemplate.letterHeaderImageUuid) {
                this.headerFileItem.push(this.patientReportTemplate.letterHeaderImage);
            }
        }
        if (type === 'signature') {
            this.signatureFileItem = [];
            if (this.patientReportTemplate.userSignatureImageUuid) {
                this.signatureFileItem.push(this.patientReportTemplate.userSignatureImage);
            }
        }
    }

    async selectTemplate(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: ReportTypeModalComponent,
            cssClass: 'full-width-modal',
            componentProps: {},
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
            const reportTemplate = await this.userService.getPatientReportTemplateByUuid(
                this.loggedInUser.groups[0],
                data.uuid,
                true,
            );
            this.patientReportForm.loadTemplate(reportTemplate);
            this.patientReportTemplate = reportTemplate;
            if (reportTemplate.letterHeaderImageUuid) this.headerFileItem.push(reportTemplate.letterHeaderImage);
            if (reportTemplate.letterFooterImageUuid) this.footerFileItem.push(reportTemplate.letterFooterImage);
            if (reportTemplate.userSignatureImageUuid) this.signatureFileItem.push(reportTemplate.userSignatureImage);
            this.patientReportForm.group.controls.reportPseudonymized.setValue(this.user.usePseudonym);
            this.patientReportForm.group.controls.visibleToPatient.setValue(reportTemplate.visibleToPatient);
            this.isTemplateSelected = true;
            this.setCss();
        }
    }

    async selectQuestionnaireSubmissions(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: QuestionnaireListModalComponent,
            backdropDismiss: true,
            cssClass: 'full-width-modal',
            componentProps: {
                myMedaxQuestionnaireSubmissions: this.myMedaxQuestionnaireSubmissions
                    ? this.myMedaxQuestionnaireSubmissions
                    : [],
                concernedUserFullName: `${this.user.lastname}, ${this.user.firstname}`,
                hasCheckbox: true,
                onlyLatestVersions: true,
                modalTitle: 'Formulare auswählen',
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
            for (const item of data) {
                if (!this.submissionsTableConfig.list.items.find((x) => x.uuid === item.uuid)) {
                    this.submissionsTableConfig.list.items.push(this.addDescriptionToSubmission(item));
                }
            }
            this.submissionsTableConfig.list = PaginatedResponse.init(this.submissionsTableConfig.list.items);
        }
    }

    addDescriptionToSubmission(submission: ExtendedSubmission): ExtendedSubmission {
        submission.description = `${submission.title}\n(${
            format(parseISO(submission.created_at), 'dd.MM.yyyy, HH:mm') + ' Uhr'
        })`;
        return submission;
    }

    removeSelectedSubmission(actionEmitter: ActionEmitter<MyMedaxQuestionnaireSubmission>): void {
        if (actionEmitter.actionType === ActionType.DELETE) {
            this.submissionsTableConfig.list.items = this.submissionsTableConfig.list.items.filter(
                (x) => x.uuid !== actionEmitter.item.uuid,
            );
            this.submissionsTableConfig.list = PaginatedResponse.init(this.submissionsTableConfig.list.items);
        }
    }

    setCss(): void {
        this.element = document.getElementById('PatientReportModalComponent');
        if (this.isTemplateSelected) {
            this.element.classList.remove('task-preview-modal');
            this.element.classList.add('full-width-modal');
        }
    }

    async selectModules(): Promise<void> {
        const availableModules = [];
        /* Stammdaten werden standardmäßig immer angehängt, daher sollte es gar nicht auswählbar sein
        if (this.loggedInUser.roles.includes(UserRoles.read_user)) {
            availableModules.push({
                name: ModuleName.USER_MASTER_DATA,
                label: ModuleLabel.USER_MASTER_DATA,
                selected: true,
                disabled: false,
            });
        }
        */
        if (
            this.configService.config.features.anamnese?.enabled &&
            this.loggedInUser.roles.includes(UserRoles.read_medical_history)
        ) {
            availableModules.push({
                name: ModuleName.MEDICAL_HISTORY,
                label: ModuleLabel.MEDICAL_HISTORY,
                selected: false,
                disabled: false,
            });
        }
        if (this.configService.config.features.chat?.enabled) {
            availableModules.push({ name: ModuleName.CHAT, label: ModuleLabel.CHAT, selected: false, disabled: true });
        }
        if (this.configService.config.features.task?.enabled) {
            availableModules.push({ name: ModuleName.TASK, label: ModuleLabel.TASK, selected: false, disabled: true });
        }
        if (this.configService.config.features.consultation?.enabled) {
            availableModules.push({
                name: ModuleName.CONSULTATION,
                label: ModuleLabel.CONSULTATION,
                selected: false,
                disabled: true,
            });
        }
        if (this.configService.config.features.order?.enabled) {
            availableModules.push({
                name: ModuleName.ORDER,
                label: ModuleLabel.ORDER,
                selected: false,
                disabled: true,
            });
        }
        if (this.configService.config.features.screening?.enabled) {
            availableModules.push({
                name: ModuleName.SCREENING,
                label: ModuleLabel.SCREENING,
                selected: false,
                disabled: true,
            });
        }
        if (this.configService.config.features.measurement?.enabled) {
            availableModules.push({
                name: ModuleName.MEASUREMENT,
                label: ModuleLabel.MEASUREMENT,
                selected: false,
                disabled: true,
            });
        }
        if (this.configService.config.features.training?.enabled) {
            availableModules.push({
                name: ModuleName.TRAINING,
                label: ModuleLabel.TRAINING,
                selected: false,
                disabled: true,
            });
        }
        if (this.configService.config.features.learning?.enabled) {
            availableModules.push({
                name: ModuleName.LEARNING,
                label: ModuleLabel.LEARNING,
                selected: false,
                disabled: true,
            });
        }
        if (this.configService.config.features.medication?.enabled) {
            availableModules.push({
                name: ModuleName.MEDICATION,
                label: ModuleLabel.MEDICATION,
                selected: false,
                disabled: true,
            });
        }
        if (this.configService.config.features.note?.enabled) {
            availableModules.push({ name: ModuleName.NOTE, label: ModuleLabel.NOTE, selected: false, disabled: true });
        }
        if (this.configService.config.features.log?.enabled) {
            availableModules.push({
                name: ModuleName.LOGBOOK,
                label: ModuleLabel.LOGBOOK,
                selected: false,
                disabled: true,
            });
        }
        const listConfig: CheckboxListConfig = {
            availableItemList: availableModules,
            splitListInColumns: true,
            listHeader: 'Datenkategorien',
            emptyListText: 'Keine Datenkategorien vorhanden',
        };
        const modal = await this.modalCtrl.create({
            component: CheckboxListModalComponent,
            cssClass: 'full-width-modal',
            componentProps: {
                title: 'Datenkategorien auswählen',
                titleIcon: 'warning-outline',
                modalBodyText: 'Bitte wählen Sie aus, welche Datenkategorien Sie anhängen möchten.',
                listConfigs: [listConfig],
                confirmButtonText: 'Auswahl bestätigen',
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (!!data && data.value.length > 0) {
            for (const item of data.value) {
                const listItem = { name: ModuleName[item], label: ModuleLabel[item] };
                if (!this.moduleTableConfig.list.items.find((x) => x.name === listItem.name)) {
                    this.moduleTableConfig.list.items.push(listItem);
                }
            }
            this.moduleTableConfig.list = PaginatedResponse.init(this.moduleTableConfig.list.items);
        }
    }

    removeSelectedModule(actionEmitter: ActionEmitter<any>): void {
        if (actionEmitter.actionType === ActionType.DELETE) {
            this.moduleTableConfig.list.items = this.moduleTableConfig.list.items.filter(
                (x) => x.name !== actionEmitter.item.name,
            );
            this.moduleTableConfig.list = PaginatedResponse.init(this.moduleTableConfig.list.items);
        }
    }

    async updateLetterRecipient(ev: SelectCustomEvent<string>): Promise<void> {
        switch (ev.detail.value) {
            case 'text':
                this.patientReportForm.group.controls.letterRecipient.patchValue(
                    this.patientReportTemplate.letterRecipient,
                );
                break;
            case UserRoles.PATIENT:
                this.patientReportForm.group.controls.letterRecipient.patchValue(this.getUserNameAndAddress(this.user));
                break;
            case UserRoles.CAREGIVER:
            case UserRoles.SUPERVISOR:
                const recipientUser = await this.selectRecipientUser(ev.detail.value);
                if (!recipientUser) break;
                this.patientReportForm.group.controls.letterRecipient.patchValue(
                    this.getUserNameAndAddress(recipientUser),
                );
                break;
            default:
                break;
        }
    }

    getUserNameAndAddress(user: User): string {
        const concatIfTruthy = (strings: string[], joinString = ' ') =>
            strings.reduce((acc, cur) => {
                if (cur) return `${acc}${joinString}${cur}`;
                return acc;
            });
        const fullUserName = concatIfTruthy([user.title, user.firstname, user.lastname]).trim();
        const userPostalCodeAndCity = concatIfTruthy([user.postalCode, user.city]).trim();
        const fullUserAddress = concatIfTruthy(
            [user.streetAddress, user.addressAddition, userPostalCodeAndCity],
            `\n`,
        ).trim();
        return concatIfTruthy([fullUserName, fullUserAddress], `\n`);
    }

    async selectRecipientUser(role: UserRoles): Promise<User> {
        const tableItems = [
            {
                id: 'selected',
                prop: 'selected',
                name: '',
                adapter: CheckBoxItemAdapterComponent,
                type: ItemType.ADAPTER,
                width: '10%',
                sortOrderWeb: 0,
            },
            {
                id: 'lastname',
                prop: 'lastname',
                name: 'Nachname',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '30%',
                sortOrderWeb: 1,
            },
            {
                id: 'firstname',
                prop: 'firstname',
                name: 'Vorname',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '30%',
                sortOrderWeb: 2,
            },
            {
                id: 'username',
                prop: 'username',
                name: 'Benutzername',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '30%',
                sortOrderWeb: 3,
            },
        ];
        let noItemsText = 'ANY_USER';
        if (role === UserRoles.SUPERVISOR) {
            noItemsText =
                'Diesem Patienten sind keine Co-Betreuer zugeordnet. Co-Betreuer können einem Patient über ' +
                'die Stammdatenverwaltung zugeordnet werden.';
        }
        const modal = await this.modalCtrl.create({
            component: UserListModalComponent,
            cssClass: 'full-width-modal',
            showBackdrop: true,
            backdropDismiss: false,
            componentProps: {
                title: 'Benutzer wählen',
                isMultipleChoice: false,
                roles: [role],
                tableItems,
                searchString: 'Benutzer suchen',
                anyItem: noItemsText,
                concernedUser: this.user.username,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data && data[0]) {
            return data[0];
        }
    }

    private createSelectList() {
        for (const template of this.pagResReportTemplates.items) {
            const selectItem = new SelectInputItem(template.uuid, template.letterSubject);
            this.templateList.push(selectItem);
        }
    }
}
